var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader),expression:"loader"}]},[_c('b-card',{staticClass:"mb-0"},[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"circle","step-size":"xs","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Contact Information","before-change":_vm.validationFormContact}},[_c('validation-observer',{ref:"administratorRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Full Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Primay Phone Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false : null,"placeholder":"Enter Phone Number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Staff Role","label-for":"roles","rules":"required"}},[_c('validation-provider',{attrs:{"name":"Staff Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticStyle:{"width":"100%"},model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}},_vm._l((_vm.staff_roles),function(role,index){return _c('el-option',{key:index,attrs:{"value":role.id,"label":role.display_name,"disabled":role.name === 'super'}})}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }